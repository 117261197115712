import React from 'react'
import styled from 'styled-components'
import { RowBetween, RowFixed, AutoRow } from 'components/Row'
import { TYPE, ExternalLink } from 'theme'
import { useEthPrices } from 'hooks/useEthPrices'
import { formatDollarAmount } from 'utils/numbers'
import Polling from './Polling'
import { FaFacebookSquare, FaLinkedin, FaTwitterSquare, FaYoutubeSquare } from 'react-icons/fa'
import ActiveBG01 from '../../assets/images/labelbg01.png'
import ActiveBG02 from '../../assets/images/labelbg02.png'

const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.black};
  padding: 10px 20px;
`

const Item = styled(TYPE.main)`
  font-size: 12px;
`

const Link = styled(ExternalLink)`
  font-size: 12px;
  color: ${({ theme }) => theme.text1};

  :hover {
    opacity: 1;
  }
`

const StyledLink = styled(ExternalLink)`
  font-size: 14px;
  height: 2rem;
  width: 7.5rem;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
  border-radius: 2px;
  white-space: nowrap;

  background: url(${ActiveBG02});
  background-size: 100% 100%;
  background-position: center;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  color: ${({ theme }) => theme.text1};
  font-weight: 400;

  :hover {
    background: url(${ActiveBG01});
    color: ${({ theme }) => theme.text2};
    background-size: 100% 100%;
    background-position: center;
    overflow: hidden;
    font-weight: 600;
    opacity: 1;
  }
`

const SocialIconsWrapper = styled.div`
  justify-content: start;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
`

const SNSBg = styled.div`
  width: 27px;
  hieght: 12px;
  background: radial-gradient(circle, rgba(82, 89, 85, 1) 53%, rgba(242, 243, 242, 1) 53%);
  margin-left: 0.6rem;
  border-radius: 3px;
  align-items: center;
  padding: 3px 0px 0px 1px;
  :hover {
    transform: scale(1.1);
    background: radial-gradient(circle, rgba(235, 13, 0, 1) 53%, rgba(242, 243, 242, 1) 53%);
    box-shadow: rgba(235, 13, 0, 1) 1px 1px 2px 1px;
  }
`

const SocialIcons = styled.div`
  color: rgba(242, 243, 242, 1);
  padding: 0px;
`

// const HeaderText = styled.div`
//   margin-right: 0.75rem;
//   font-size: 0.825rem;
//   font-weight: 500;
//   display: inline-box;
//   display: -webkit-inline-box;
//   opacity: 0.8;
//   :hover {
//     opacity: 1;
//   }
//   a {
//     color: ${({ theme }) => theme.text1};
//   }
// `

const TopBar = () => {
  const ethPrices = useEthPrices()
  return (
    <Wrapper>
      <RowBetween>
        <Polling />
        <AutoRow gap="6px">
          <RowFixed>
            <Item>ETH Price:</Item>
            <Item fontWeight="700" ml="4px">
              {formatDollarAmount(ethPrices?.current)}
            </Item>
          </RowFixed>
        </AutoRow>
        <AutoRow gap="6px" style={{ justifyContent: 'flex-end' }}>
          <StyledLink href="https://gistswap.org/">Launch App</StyledLink>
          {/* <ButtonLink>
            <Link
              style={{
                color: 'white',
                fontSize: '14px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              target="_blank"
              href="https://gistswap.org"
            >
              <HeaderText>Launch App</HeaderText>
            </Link>
          </ButtonLink> */}
          <SocialIconsWrapper>
            <SNSBg>
              <Link
                style={{ color: 'white', textDecoration: 'underline' }}
                target="_blank"
                href="https://twitter.com/fighterkerry?ref_src=twsrc%5Etfw%7Ctwcamp%5Eembeddedtimeline%7Ctwterm%5Eprofile%3Afighterkerry%7Ctwgr%5EeyJ0ZndfZXhwZXJpbWVudHNfY29va2llX2V4cGlyYXRpb24iOnsiYnVja2V0IjoxMjA5NjAwLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X2hvcml6b25fdHdlZXRfZW1iZWRfOTU1NSI6eyJidWNrZXQiOiJodGUiLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3NwYWNlX2NhcmQiOnsiYnVja2V0Ijoib2ZmIiwidmVyc2lvbiI6bnVsbH19&ref_url=https%3A%2F%2Finfonet.gist.ac.kr%2F"
              >
                <SocialIcons>
                  <FaTwitterSquare size="25px" />
                </SocialIcons>
              </Link>
            </SNSBg>
            <SNSBg>
              <Link
                style={{ color: 'white', textDecoration: 'underline' }}
                target="_blank"
                href="https://www.facebook.com/Gistswap-110453904854267"
              >
                <SocialIcons>
                  <FaFacebookSquare size="25px" />
                </SocialIcons>
              </Link>
            </SNSBg>
            <SNSBg>
              <Link
                style={{ color: 'white', textDecoration: 'underline' }}
                target="_blank"
                href="https://kr.linkedin.com/in/heung-no-lee-4808b38"
              >
                <SocialIcons>
                  <FaLinkedin size="25px" />
                </SocialIcons>
              </Link>
            </SNSBg>
            <SNSBg>
              <Link
                style={{ color: 'white', textDecoration: 'underline' }}
                target="_blank"
                href="https://www.youtube.com/channel/UCWtKh2U303FrG3aNUNsqO-w"
              >
                <SocialIcons>
                  <FaYoutubeSquare size="25px" />
                </SocialIcons>
              </Link>
            </SNSBg>
          </SocialIconsWrapper>
          {/* <HeaderText style={{ color: '#525955', paddingLeft: '11%' }}>©2022 GISTSWAP</HeaderText> */}
          {/* <StyledLink href="https://v2.info.uniswap.org/#/">V2 Analytics</StyledLink>
          <StyledLink href="https://docs.uniswap.org/">Docs</StyledLink>
          <StyledLink href="https://app.uniswap.org/#/swap">App</StyledLink> */}
        </AutoRow>
      </RowBetween>
    </Wrapper>
  )
}

export default TopBar
